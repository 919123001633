import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css'

const getCurrentCountryCode = async (callback: (countryCode: string) => void) => {
  let countryCode = localStorage.getItem("countryCode");
  if (countryCode) {
    callback(countryCode);
  } else {
    try {
      const response = await fetch("https://freeipapi.com/api/json", {
        headers: {Accept: "application/json"},
      });
      const data = await response.json();
      countryCode = data.countryCode;
      if (countryCode) {
        localStorage.setItem("countryCode", countryCode);
      } else {
        countryCode = "th";
      }
      callback(countryCode);
    } catch (error) {
      callback("th")
    }
  }
}
export const initInterPhone = (id: string) => {
  const inputPhone = document.querySelector("#" + id);
  if (inputPhone) {
    return intlTelInput(inputPhone, {
      initialCountry: "auto",
      geoIpLookup: getCurrentCountryCode,
      utilsScript: '/js/utils.js',
    })
  }
}

export default {initInterPhone};
